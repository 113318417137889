import * as React from "react";
import {useAppStore} from "../../../../../../state/app_state";
import {ArticleFeedViewModel} from "../model/article-feed-view-model";
import ArticleFeedCard from "../components/card/article-feed-card";

export type ArticleFeedContentProps = {
	viewModel: ArticleFeedViewModel
}

export function ArticleFeedContent({viewModel}: ArticleFeedContentProps) {

	// State
	const setArticlesSection = useAppStore(state => state.setArticles);
	setArticlesSection();

	return (
		<>
			{/*<pre>{JSON.stringify(viewModel, null, 4)}</pre>*/}
			<div className="layout-containerized font-sans">
				<div id="main-feed" className="my-8 grid grid-cols-12 gap-4 justify-evenly">
					{viewModel.props.items.map((item) =>
						<div key={`div-${item.props.slug}`} className="col-span-12 md:col-span-6 lg:col-span-4 rounded-lg">
							<ArticleFeedCard key={`card-${item.props.slug}`} viewModel={item}/>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
