import {graphql} from "gatsby";
import {
    MapArticleFeedGraphResponseToViewModel
} from "../modules/article/submodule/overview/application/usecase/map-article-feed-graph-response-to-view-model";
import Layout from "../components/layout";
import {ArticleFeedContent} from "../modules/article/submodule/overview/presentation/page/article-feed-content";
import * as React from "react";
import {useI18next} from "gatsby-plugin-react-i18next";
import {
    MapArticleGraphResponseToViewModel
} from "../modules/article/submodule/article/application/usecase/map-article-graph-response-to-view-model";
import ArticleFeedSeo from "../modules/article/submodule/overview/presentation/components/seo/article-feed-seo";

/*

{
  "language": "de",
  "flavourPubLevel": ["draft", "published"]
}

 */
export const query = graphql`
query ($language: String!, $flavourPubLevel: [String]) {
  directus {
    articles {
      category
      date_created
      date_updated
      status
      translations(
        filter: {languages_code: {locale: {_eq: $language}}, status: {_in: $flavourPubLevel}}
      ) {
        id
        slug
        headline
        subline
        intro
        keywords
        languages_code {
          locale
        }
      }
      umbrella_slug
    }
  }
  pageContent: directus {
    article_feed {
      id
      status
      translations(
        filter: {languages_code: {locale: {_eq: $language}}, status: {_in: $flavourPubLevel}}
      ) {
        languages_code {
          code
          locale
          name
        }
        meta_description
        meta_title
        slug
        status
      }
    }
  }
  language_alternates: directus {
    article_feed {
      id
      status
      translations(filter: {status: {_in: $flavourPubLevel}}) {
        languages_code {
          code
          locale
          name
        }
        meta_description
        meta_title
        slug
        status
      }
    }
  }
  allFile(
    filter: {relativePath: {regex: "/articles/"}, name: {regex: "/opener/"}}
  ) {
    edges {
      ...ImageData
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`


export default function ArticleFeedTemplate({data}: any) {

    const {language} = useI18next();
    // Execute use case
    const useCase = new MapArticleFeedGraphResponseToViewModel(data);
    const viewModel = useCase.execute();

    return (
        <>
            <ArticleFeedSeo locale={language} viewModel={viewModel}/>
            {/*<pre>{JSON.stringify(data, null, 4)}</pre>*/}
            <Layout>
                <ArticleFeedContent viewModel={viewModel}/>
            </Layout>
        </>
    )
}