import {graphql, useStaticQuery} from "gatsby"
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {useI18next} from "gatsby-plugin-react-i18next";
import React from "react";
import {ArticleFeedViewModel} from "../../model/article-feed-view-model";
import metaPublisherJson from "../../../../../../shared/seo/presentation/seo-meta-publisher";

const getLogoUrl = () => {
	const data = useStaticQuery(graphql`
		{
		  file(name: {eq: "sushi-pedia-logo"}) {
			publicURL
		  }
		}
	  `)
	return data.file.publicURL
}


export interface ArticleSeoProps {
	locale: string
	viewModel: ArticleFeedViewModel
}

export default function ArticleFeedSeo({locale, viewModel}: ArticleSeoProps) {

	const {t} = useI18next();
	const metaTags = metaPublisherJson()

	const languageAlternates = viewModel.props.alternate_languages.map((translation) => {
		return {
			hrefLang: translation.locale,
			href: t("Site.url") + "/" + translation.locale + "/" + translation.slug + "/",
		}
	})

	return (
		<>
			<GatsbySeo
				canonical={t("Site.url") + "/" + locale + "/" + viewModel.props.slug + "/"}
				language={locale}
				title={viewModel.props.meta.title}
				description={viewModel.props.meta.description}
				languageAlternates={languageAlternates}
				metaTags={metaTags}
			/>
		</>
	)
}

