import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {FileNode} from "gatsby-plugin-image/dist/src/components/hooks";
import {GraphImageNode} from "../../../../../shared/infrastructure/graph-image-node";

export interface ArticleFeedItemViewModelProps {
	locale: string,
	headline: string,
	intro: string
	keywords: string[],
	slug: string,
	image_opener_gatsby: FileNode | GraphImageNode
}

export class ArticleFeedItemViewModel extends ViewModel<ArticleFeedItemViewModelProps> {

	private constructor(props: ArticleFeedItemViewModelProps) {
		super(props);
	}

	public static create(props: ArticleFeedItemViewModelProps): ArticleFeedItemViewModel {
		return new ArticleFeedItemViewModel(props);
	}

}
