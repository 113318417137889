import {ArticlesFeedResponse} from "../../infrastructure/dto/article-feed-response";
import {ArticleFeedViewModel, ArticleFeedViewModelProps} from "../../presentation/model/article-feed-view-model";
import {ArticleFeedItemViewModel} from "../../presentation/model/article-feed-item-view-model";
import {ArticleFeedItemFactory} from "../../presentation/factory/article-feed-item-factory";
import {GraphImagesEdge} from "../../../../../shared/infrastructure/graph-image-node";

export class MapArticleFeedGraphResponseToViewModel {
    private readonly response: ArticlesFeedResponse;

    constructor(response: ArticlesFeedResponse) {
        this.response = response;
    }

    public execute(): ArticleFeedViewModel {
        const props: ArticleFeedViewModelProps = {
            alternate_languages: this.map_alternate_languages(),
            items: this.map_feed_items(),
            slug: this.map_slug(),
            meta: this.map_meta()
        }
        return ArticleFeedViewModel.create(props)
    }

    private map_feed_items(): ArticleFeedItemViewModel[] {
        return this.response.directus.articles.map((article) => {
            return article.translations.map((translation) => {
                const filtered_opener_image = this.filter_opener_image(
                    article.umbrella_slug,
                    translation.languages_code.locale
                );
                return new ArticleFeedItemFactory(translation, filtered_opener_image).build()
            })
        }).flat()

    }

    /**
     * Find the opener image that belongs the article
     * @param umbrellaSlug
     * @param locale
     * @private
     */
    private filter_opener_image(umbrellaSlug: string, locale: string): GraphImagesEdge {
        // Search for opener in locale language
        const image_data_found_for_locale = this.response.allFile.edges
            .filter((edge) => (edge.node.relativePath
                .includes(umbrellaSlug)
                && edge.node.relativePath.includes(locale)
                && edge.node.relativePath.includes('opener')));

        if (image_data_found_for_locale.length > 1) {
            return image_data_found_for_locale[0];
        } else {
            // Search for shared opener
            const image_data_found = this.response.allFile.edges.filter((edge) => (edge.node.relativePath
                .includes(umbrellaSlug)
                && edge.node.relativePath.includes('opener')));
            return image_data_found[0];
        }
    }

    private map_meta(): {
        description: string,
        title: string
    } {
        // filtered by query
        const translation = this.response.pageContent.article_feed[0].translations[0];
        return {
            description: translation.meta_description,
            title: translation.meta_title
        }
    }

    private map_alternate_languages(): Array<{locale: string, slug: string}> {
        return this.response.language_alternates.article_feed.map((feed) => {
            return feed.translations.map((translation) => {
                return {
                    locale: translation.languages_code.locale,
                    slug: translation.slug
                }
            })
        }).flat()
    }

    private map_slug(): string {
        // filtered by query
        return this.response.pageContent.article_feed[0].translations[0].slug
    }
}