import {Translation} from "../../infrastructure/dto/article-feed-response";
import {GraphImageNode, GraphImagesEdge} from "../../../../../shared/infrastructure/graph-image-node";
import {ArticleFeedItemViewModel, ArticleFeedItemViewModelProps} from "../model/article-feed-item-view-model";

export class ArticleFeedItemFactory {
    private readonly articleTranslation: Translation;
    private readonly openerImage: GraphImagesEdge;

    /**
     *
     * @param articleTranslation GraphResponse
     * @param openerImage GraphResponse
     */
    constructor(
        articleTranslation: Translation,
        openerImage: GraphImagesEdge
    ) {
        this.articleTranslation = articleTranslation;
        this.openerImage = openerImage;
    }

    public build(): ArticleFeedItemViewModel {
        const props: ArticleFeedItemViewModelProps = {
            headline: this.map_headline(),
            image_opener_gatsby: this.map_opener_image(),
            intro: this.map_intro(),
            keywords: this.map_keywords(),
            locale: this.map_locale(),
            slug: this.map_slug()
        }
        return ArticleFeedItemViewModel.create(props);
    }

    private map_headline(): string {
        return this.articleTranslation.headline;
    }

    private map_opener_image(): GraphImageNode {
        return this.openerImage.node;
    }

    private map_intro(): string {
        return this.articleTranslation.intro;
    }

    private map_keywords(): string[] {
        const keywords = this.articleTranslation.keywords;
        if (keywords) {
            if (keywords.length > 0) {
                return keywords
            } else {
                return []
            }
        } else {
            return []
        }
    }

    private map_locale(): string {
        return this.articleTranslation.languages_code.locale;
    }

    private map_slug(): string {
        return this.articleTranslation.slug
    }

}