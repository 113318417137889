import {GatsbyImage, getImage, ImageDataLike} from "gatsby-plugin-image";
import * as React from "react";
import {ArticleFeedItemViewModel} from "../../model/article-feed-item-view-model";
import {navigate} from "gatsby"
import {useI18next} from "gatsby-plugin-react-i18next";


export interface ArticleFeedCardProps {
    viewModel: ArticleFeedItemViewModel
}

export default function ArticleFeedCard({viewModel}: ArticleFeedCardProps) {

    const {t} = useI18next();
    const image_data = viewModel.props.image_opener_gatsby as ImageDataLike;
    const gatsbyData = getImage(image_data);

    return (
        <>
            <div className="p-0">
                <div className="max-w rounded overflow-hidden shadow-lg">
                    {gatsbyData && <GatsbyImage className={"rounded"} alt={"TODO"} image={gatsbyData}/>}

                    <div id="keywords" className="px-6 py-3">
                        {viewModel.props.keywords && viewModel.props.keywords.map((keyword) =>
                            <span key={keyword}
                                  className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">{keyword}</span>
                        )}
                    </div>

                    <div id="preview" className="px-6 py-4">
                        {/* Title */}
                        <div className="font-bold text-xl">
                            <a className="hover:text-salmonRed"
                               href={`/${viewModel.props.locale}/${viewModel.props.slug}`}>
                                {viewModel.props.headline}
                            </a>
                        </div>
                        <p className="text-gray-700 text-base py-2">
                            {viewModel.props.intro}
                        </p>

                        <button
                            className="bg-salmonRed hover:bg-noriGreen text-white font-bold text-sm py-2 px-4 my-4 rounded"
                            onClick={() => navigate(`/${viewModel.props.locale}/${viewModel.props.slug}`)}
                        >
                            {t("ArticleFeed.read_more")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
