import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {ArticleFeedItemViewModel} from "./article-feed-item-view-model";

export interface ArticleFeedViewModelProps {
	meta: {
		title: string,
		description: string
	}
	slug: string
	alternate_languages: Array<{locale: string, slug: string}>
	items: ArticleFeedItemViewModel[]
}

export class ArticleFeedViewModel extends ViewModel<ArticleFeedViewModelProps> {

	private constructor(props: ArticleFeedViewModelProps) {
		super(props);
	}

	public static create(props: ArticleFeedViewModelProps): ArticleFeedViewModel {
		return new ArticleFeedViewModel(props);
	}


}